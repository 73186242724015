import PropTypes from 'prop-types';
import { components } from 'react-select';

const propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    color: PropTypes.number,
  }).isRequired,
  innerProps: PropTypes.shape({
    className: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
const defaultProps = {};

function SelectColorMultiValueContainer(props) {
  // eslint-disable-next-line no-param-reassign
  props.innerProps.className += ` react-select__multi-value--colored color-coding--${
    props.data.color || 'none'
  }`;

  return <components.MultiValueContainer {...props} />;
}

SelectColorMultiValueContainer.propTypes = propTypes;
SelectColorMultiValueContainer.defaultProps = defaultProps;

export default SelectColorMultiValueContainer;
